import { FC } from "react";
import Title from "Components/Title";

type Props = {
  isTopic: boolean;
  name: string;
};

const Topics: FC<Props> = ({ isTopic, name = "" }) => {
  if (!isTopic) {
    return null;
  }
  return <Title type="topics">{name}</Title>;
};

export default Topics;
